.contactPage {
  position: relative;
  &__modal {
    .ant-modal-header {
      padding: 0 20px;
    }
    .ant-modal-footer {
      //display: none;
      background: linear-gradient(
        90deg,
        var(--primary) 0%,
        var(--secondary) 100%
      );
      box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
      button {
        display: none;
      }
    }
    .ant-modal-close-x {
      color: #fff;
    }
  }
  &__footer {
    .anticon {
      color: #fff;
      font-size: 2rem;
      cursor: pointer;
    }
  }
  &__head {
    &-logo {
      max-width: 300px;
      height: auto;
      background: #fff;
      padding: 5px;
      text-align: center;
      position: relative;
    }
    .profile {
      &__container {
        position: relative;
        width: 100%;
        color: var(--light-grey);
        padding: 32px 16px;

        &--right {
          height: 160px;
          a {
            padding: 5px;
          }
          .anticon {
            color: #fff;
            font-size: 2rem;
            cursor: pointer;
          }
        }
        &--left {
          height: 160px;
        }
      }
      &__data {
        position: relative;
        margin-left: 20px;
        color: #fff;
        font-size: 1rem;
        .anticon {
          color: #fff;
          font-size: 2rem;
          cursor: pointer;
        }
        h2 {
          color: #fff;
        }
        &-function {
          // margin-top: 20px;
          a {
            color: #fff !important;
            text-decoration: underline;
          }
        }
        .help-btn {
          color: #fff;
          font-weight: bold;
          margin-top: 12px;
          &:hover,
          &:focus,
          &:active {
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }
  }
  &__body {
    margin-top: -130px;
    padding: 0 !important;
    h2 {
      color: var(--secondary) !important;
    }
    &-card {
      margin-bottom: 20px !important;
      box-shadow: -5px 3px 3px 1px rgba(0, 0, 0, 0.1);
      &--custom {
        .ant-card-head-title {
          padding: 10px 0 0;
        }
      }
    }
    .detail-pane {
      background: #fff;
      // border-radius: 4px;
    }
  }
}
