@import "../../assets/styles/colors";

.ant-tabs-nav-wrap {
  //background-color: #fff;
  user-select: none;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ant-tabs-nav {
  &:before {
    border-bottom: none !important;
  }
}

.ant-tabs {
  overflow: visible !important;
}

.ant-tabs-tab {
  text-transform: uppercase;
  color: #c9ddff;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
  font-weight: bold !important;
}

.ant-tabs-ink-bar-animated {
  transition: width 0.3s, left 0.3s, right 0.3s !important;
  //border-radius: 10px 10px 0 0;
  height: 3px !important;
}

.ant-tabs-nav-operations-hidden {
  display: none;
}

.ant-tabs-content-holder {
  margin-top: 8px;
}
