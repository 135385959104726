.login {
  width: 100vw;
  height: 100vh;
  //background: linear-gradient(315deg, #fff, #eff5ff);
  //background: linear-gradient(315deg, #fff, #FFF, var(--primary));
  &__container {
    position: relative;
    max-width: 680px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    font-size: 14px;
    overflow: hidden;
  }
  &__logoContainer {
    color: var(--white);
    padding: 35px 35px 20px;
    width: 40%;
    position: relative;
    overflow: hidden;
    background: url("../../assets/images/login_img.png") rgba(0, 102, 102, 0.1);
    background-size: cover;
    background-position-x: center;
    .logo {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
    h1 {
      position: absolute;
      top: 10px;
      left: 10px;
      &:before {
        content: "";
        background: none;
        padding: 0;
      }
    }
  }
  &__content {
    background: #fff;
    padding: 35px 35px 20px;
    width: 60%;
    form {
      width: 100%;
    }
    .ant-form-item-control {
      margin-bottom: 10px;
    }
    button {
      background-color: var(--primary);
      border-color: var(--primary);
      &:hover {
        background: var(--primary);
        border-color: var(--primary);
      }
    }
  }
}
