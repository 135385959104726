.homePage {
  &__titleContainer {
    background: linear-gradient(
      90deg,
      var(--primary) 0%,
      var(--secondary) 100%
    );
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
    padding: 10px 25px 2px 25px;

    &-button {
      min-width: 120px;
    }
  }

  &__newsSection {
    background-color: #ffffff;
    box-shadow: -1px 2px 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 0;

    &__newsContainer {
      overflow: auto;
      background-color: #ffffff;
      border-radius: 10px;

      &-newsCard {
        border-radius: 10px;
        background-color: #ffffff;
      }

      &-empty {
        text-align: center;
        height: 1000px;
        padding-top: 100px;
      }
    }
  }

  &__creaContainer {
    background-color: #ffffff;
    box-shadow: -1px 2px 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 40px;

    &__creaCard {
      padding: 20px 30px;
    }
  }

  &__pitchContainer {
    background-color: #ffffff;
    box-shadow: -1px 2px 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    //height: 405px;
    overflow: hidden;

    &__pitchCard {
      overflow-y: auto;
      overflow-x: hidden;
      //height: 350px;
    }
  }

  &__contactsContainer {
    background-color: #ffffff;
    box-shadow: -1px 2px 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    max-height: 405px;
    overflow: hidden;

    &__pitchCard {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  &__tagsContainer {
    background-color: #ffffff;
    box-shadow: -1px 2px 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    p {
      margin: 0;
      padding: 0;
    }

    &__tagsCard {
      height: 214px;
      overflow: auto;

      &-circle {
        width: 15px;
        height: 15px;
        border-radius: 50px;
        margin-top: 11px;
      }
    }
  }
}
