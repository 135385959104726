@import "colors";

.muted-link {
  color: inherit;
  text-decoration: underline;
}
.ant-btn {
  &.ant-btn-primary {
    background-color: $primaryColor;
    border-color: $primaryColor;
    &:hover,
    &:active,
    &:focus {
      background-color: $primaryColor;
      border-color: $primaryColor;
      opacity: 0.8;
    }
  }
}
