.banner-top {
  h1,
  h2 {
    //padding: 20px 0;
    margin-bottom: 0;
    color: #fff;
  }
  border-radius: 0px 0px 10px 10px;
  width: calc(100% + 40px);
  left: -20px;
  height: 270px;
  background: linear-gradient(90deg, var(--primary) 0%, var(--secondary) 100%);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  padding: 0 20px 30px;
  &--small {
    height: 190px;
  }
  &:after,
  &:before {
    position: absolute;
    display: block;
    content: "";
    height: 340px;
    width: 340px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.05);
  }
  &:before {
    right: -85px;
    bottom: -200px;
  }
  &:after {
    left: -85px;
    top: -200px;
  }
  .square {
    position: absolute;
    width: 80px;

    &--left {
      z-index: 0;
      transform: rotate(45deg);
      top: 85px;
      left: 150px;
    }
    &--right {
      z-index: 0;
      bottom: 80px;
      right: 60px;
    }
  }
}
