@import "../../assets/styles/colors";

.searchPage {
  &__orders,
  &__lineFilters {
    color: #fff;
    &-icon {
      margin-left: 10px;
      font-size: 12px;
    }
    .ant-btn-link {
      color: #fff;
      &.selected {
        color: #fff;
        border-radius: 0;
        border-bottom: 1px solid #fff;
      }
    }
  }
  &__orders {
    background: linear-gradient(
      90deg,
      var(--primary) 0%,
      var(--secondary) 100%
    );
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
    padding: 10px 25px;
    margin: 0 0 20px;
    &-button {
      min-width: 120px;
    }
  }
  &__filters {
    margin-top: -100px;
    .ant-select-arrow {
      right: 18px;
      margin-top: -10px;
    }
    .ant-select-clear {
      margin-top: -8px;
      right: 50px;
      width: 8px;
    }
    .customCard .anticon {
      font-size: 1rem;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      font-size: 1rem;
      color: $primaryColor;
      font-weight: bold;
    }
  }
  &__list {
  }
  &__item {
    background: #fff;
    border-bottom: 1px solid var(--light-grey);
    margin: 10px 0;
    border-radius: 10px;
    transition: all 100ms ease;
    box-shadow: -1px 2px 5px 2px rgba(0, 0, 0, 0.1);
    .ant-list-item-meta-avatar {
      height: 100px;
      .ant-row-middle {
        height: 100%;
      }
    }
    &:hover {
      margin-left: 3px;
      margin-right: -3px;
      box-shadow: 3px 5px 15px 0px rgba(15, 15, 15, 0.09);
    }
    &-more {
      font-size: 2rem;
      color: #000;
    }
    &-icon {
      color: $secondaryColor !important;
      font-size: 1.5rem;
    }
    &-logo {
      height: 100px;
      padding: 5px;
      text-align: center;
      position: relative;
      margin: 0 10px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      &--placeholder {
        width: 205px;
      }
    }
    .ant-list-item-meta-avatar {
      width: 205px;
      text-align: center;
    }
    &-avatar {
      margin: 3px 0 0 10px !important;
    }
    &-title {
      color: var(--secondary);
    }
    &-tag {
      border-color: white !important;
      color: white !important;
    }
    &-clc {
      padding: 12px 0 8px 16px;
    }
  }
  &__tabs {
    margin-top: -190px;
    .ant-tabs-tab {
      text-transform: uppercase;
      //color: var(--secondary);
      color: var(--accent);
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      //color: var(--secondary) !important;
      color: var(--menu-active) !important;
      font-weight: bold !important;
    }
  }
}
.fast-search__dropdown {
  .rc-virtual-list-holder {
    max-height: 700px !important;
  }
  &-title {
    color: $primaryColor;
    font-weight: bold;
    font-size: 1rem;
  }
  &--other {
    color: $primaryColor;
    border-bottom: 1px solid $primaryColor;
  }
}
